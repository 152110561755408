import Table from "../Table/Table";
import { Row } from "react-table";
import styles from "./RulesTable.module.scss";
import { RuleNotificationType, RuleSchedule } from "../../api/data-contracts";
import { useMemo, useReducer, useState } from "react";
import RulesTableController from "./RulesTableController";
import { EMPTY_ARR } from "../../util/shared.util";
import { generateRulesColumns, queryResultToTableData } from "./rule-table.util";
import { modalInitialState, modalReducer } from "../../util/reducers/modal-reducer.util";
import { SelectedType, useTspFleetContext } from "../../context/TspFleetContext/TspFleetContext";
import { useAuthContext } from "../../context/AuthContext";
import useQueryRules from "../../hooks/useQueryRules";
import AddEditRuleModal from "./modals/UpsertRuleModal/AddEditRuleModal";
import DeleteRuleModal from "./modals/DeleteRuleModal";
import { ReactComponent as NoNotificationsIcon } from "../../assets/icons/NoNotifications.svg";
import { FormattedMessage } from "react-intl";
import { useQueryClient } from "@tanstack/react-query";

export interface RuleTableEntry {
  Id?: number;
  name?: string;
  description?: string;
  ruleSchedules?: RuleSchedule[];
  ruleNotificationTypes?: RuleNotificationType[];
  createdDateTime?: string;
  createdUserName?: string;
}
type RuleModalName = "ADD" | "EDIT" | "DELETE";
export interface RuleModalData {
  notifications?: RuleTableEntry[];
  tspTreeSelected?: SelectedType;
}

function RuleTable() {
  const { data, isError, isLoading, isFetching } = useQueryRules(queryResultToTableData);

  const [selectedRows, setSelectedRows] = useState<Row<RuleTableEntry>[]>([]);
  const selectedNotifications = useMemo(() => selectedRows.map((row) => row.original), [selectedRows]);

  const [modalState, dispatch] = useReducer(modalReducer<RuleModalName, RuleModalData>, modalInitialState);
  const { loggedUser } = useAuthContext();
  const columns = useMemo(() => generateRulesColumns(loggedUser), [loggedUser]);
  const { selected: tspTreeSelected } = useTspFleetContext();

  const queryClient = useQueryClient();

  const NoNotificationsContent = () => {
    return (
      <div className={styles.center}>
        <NoNotificationsIcon />
        <div className="mt-3"><FormattedMessage id="NOTIFICATION.EMPTYDATA" defaultMessage="There are no notification rules defined yet." /></div>
        <div><FormattedMessage id="NOTIFICATION.EMPTYDATA_ADD" defaultMessage="To add, click on the Create button above." /></div>
      </div>
    )
  }

  return (
    <div className="h-100 d-flex flex-column">
      <div className="px-3">
        {
          (
            <RulesTableController
              notificationsCount={data?.totalCount}
              disable={{
                add: false,
                delete:
                  selectedNotifications.length !== 1,
                edit: selectedNotifications.length !== 1,
              }}
              onClickAdd={() => {
                dispatch({
                  type: "SET_SHOW",
                  payload: { modalName: "ADD", data: { tspTreeSelected } },
                });
              }}
              onClickEdit={() => {
                queryClient.invalidateQueries({ queryKey: ["notification"] })
                dispatch({
                  type: "SET_SHOW",
                  payload: { modalName: "EDIT", data: { notifications: selectedNotifications, tspTreeSelected } },
                })
              }
              }
              onClickDelete={() =>
                dispatch({
                  type: "SET_SHOW",
                  payload: { modalName: "DELETE", data: { notifications: selectedNotifications } },
                })
              }
            />
          )}
        <Table<RuleTableEntry>
          data={data?.data || EMPTY_ARR}
          columns={columns}
          classes={{
            wrapper: () => styles["table-wrapper"],
            table: () => styles.table,
            thead: () => styles.thead,
            th: () => styles.th,
            tr: (row) => `${styles.tr} ${row.isSelected ? styles["selected-row"] : ""}`,
            td: () => styles.td,
          }}
          isLoading={isLoading}
          isError={isError}
          onChangeSelectedRows={setSelectedRows}
          noDataContent={!isLoading && !isFetching && !isError
            && (!data || !((data as any).data) || data?.data?.length === 0)
            && <NoNotificationsContent />}
        />
      </div>
      {/* <Pagination
        className="mt-auto"
        debouncedPageIndex={queryParams.paging.pageIndex}
        onChangePageIndex={setPageIndex}
        pageCount={data?.pageCount}
        allDisabled={isFetching}
        totalEntries={data?.totalCount}
      /> */}
      {modalState.shownModal === "ADD" && (
        <AddEditRuleModal
          onClickCancel={() => dispatch({ type: "CLEAR" })}
          onClickOk={() => dispatch({ type: "CLEAR" })}
          tspTreeSelected={modalState.data?.tspTreeSelected}
        />
      )}
      {modalState.shownModal === "EDIT" && (
        <AddEditRuleModal
          onClickCancel={() => dispatch({ type: "CLEAR" })}
          onClickOk={() => dispatch({ type: "CLEAR" })}
          notificationInitialData={modalState.data?.notifications?.[0]}
          tspTreeSelected={modalState.data?.tspTreeSelected}
        />
      )}
      <DeleteRuleModal
        show={modalState.shownModal === "DELETE"}
        onClickNo={() => dispatch({ type: "CLEAR" })}
        onClickYes={() => dispatch({ type: "CLEAR" })}
        ruleData={modalState.data?.notifications?.[0]!}
      />
    </div>
  );
}

export default RuleTable;
