import { Row, TableInstance } from "react-table";
import { EventMessage, MediaSource, MediaType } from "../../api/data-contracts";
import { GetEventsResult } from "../../services/events.service";
import { EventsTableEntry, MediaDetails } from "./EventsTable";

export function messageToMediaDetails(msg: EventMessage): MediaDetails[] {
  const mediaArr = [];
  for (let i = 0; i < (msg.cabinImageCount || 0); ++i) {
    mediaArr.push({
      type: MediaType.Image,
      source: MediaSource.CABIN,
      index: i,
    });
  }
  for (let i = 0; i < (msg.cabinVideoCount || 0); ++i) {
    mediaArr.push({
      type: MediaType.Video,
      source: MediaSource.CABIN,
      index: i,
    });
  }
  for (let i = 0; i < (msg.roadImageCount || 0); ++i) {
    mediaArr.push({
      type: MediaType.Image,
      source: MediaSource.ROAD,
      index: i,
    });
  }
  for (let i = 0; i < (msg.roadVideoCount || 0); ++i) {
    mediaArr.push({
      type: MediaType.Video,
      source: MediaSource.ROAD,
      index: i,
    });
  }
  return mediaArr;
}



export function messageToMediaDetailsDefault(msg: EventMessage): MediaDetails[] {
  const mediaArr = [];
  for (let i = 0; i < (msg.cabinImageCount || 1); ++i) {
    mediaArr.push({
      type: MediaType.Image,
      source: MediaSource.CABIN,
      index: i,
      nodata: !msg.cabinImageCount
    });
  }
  for (let i = 0; i < (msg.cabinVideoCount || 1); ++i) {
    mediaArr.push({
      type: MediaType.Video,
      source: MediaSource.CABIN,
      index: i,
      nodata: !msg.cabinVideoCount
    });
  }
  for (let i = 0; i < (msg.roadImageCount || 1); ++i) {
    mediaArr.push({
      type: MediaType.Image,
      source: MediaSource.ROAD,
      index: i,
      nodata: !msg.roadImageCount
    });
  }
  for (let i = 0; i < (msg.roadVideoCount || 1); ++i) {
    mediaArr.push({
      type: MediaType.Video,
      source: MediaSource.ROAD,
      index: i,
      nodata: !msg.roadVideoCount
    });
  }
  return mediaArr;
}

function messageToEventEntrie(msg: EventMessage, isDetail: boolean = false): EventsTableEntry {
  return {
    vehicleType: msg.vehicleType,
    vehicleId: msg.vehicleId,
    driverId: msg.driverID,
    type: msg.eventType,
    mediaArr: isDetail ? messageToMediaDetailsDefault(msg) : messageToMediaDetails(msg),
    deviceId: msg.deviceID,
    messageId: msg.messageID,
    dateTime: msg.eventDateTime,
    speed: msg.speed,
    tripId: msg.tripID,
    locationAddr: msg.lastKnownStreetAddress,
    eventAttr1: msg.eventAttr1,
    notificationType: msg.notificationType,
    locationTime: msg.locationTime,
    locationLat: msg.lastKnownLocationLat,
    locationLong: msg.lastKnownLocationLong,
    headingAngle: msg.headingAngle,
    category: msg.category,
    driverName: msg.driverName
  };
}

function messagesToEventEntries(messages: EventMessage[]): EventsTableEntry[] {
  return messages.map(
    (msg): EventsTableEntry => (messageToEventEntrie(msg))
  );
}

export function queryResultToTableData(queryResult: GetEventsResult) {
  return {
    pageCount: queryResult?.pageCount,
    totalCount: queryResult.totalCount,
    data: messagesToEventEntries(queryResult?.data || []),
  };
}

export function queryResultToDetailsData(queryResult: GetEventsResult) {
  return {
    pageCount: queryResult?.pageCount,
    totalCount: queryResult.totalCount,
    eventDetails: queryResult?.data && queryResult?.data.length > 0 ? messageToEventEntrie(queryResult?.data[0], true) : null,
  };
}

function isExpandable(row: Row<EventsTableEntry>) {
  const values = row.original;
  return values.deviceId && values.messageId && values.mediaArr.length;
}

export function onClickRow(row: Row<EventsTableEntry>, table: TableInstance<EventsTableEntry>) {
  const isRowExpanded = row.isExpanded;
  table.toggleAllRowsExpanded(false);
  if (!isRowExpanded && isExpandable(row)) {
    row.toggleRowExpanded(true);
  }

  const isRowSelected = row.isSelected;
  table.toggleAllRowsSelected(false);
  if (!isRowSelected) {
    row.toggleRowSelected(true);
  }
}
