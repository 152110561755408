import { useEffect, useMemo, useState } from "react";
import styles from "./EventDetailModal.module.scss";
import {Tab, Tabs } from "react-bootstrap";
import { EventsTableEntry } from "../EventsTable";
import { sortMedia } from "../../FleetView/LastEvent/LastEventMedia";
import { eventsService } from "../../../services/events.service";
import Media from "../../Media/Media";
import { MediaType } from "../../../api/data-contracts";
import Video from "../../../icons/Video";
import { Color } from "../../../constants";
import Photo from "../../../icons/Photo";
import "./EventDetailModal.css";
import greenCirclePath from "../../../assets/green_circle.svg";

interface EventDetailTabsProps {
    event?: EventsTableEntry | null | undefined;
}

function EventDetailMediaTabs({ event }: EventDetailTabsProps) {

    const sortedMediaArr = useMemo(() => [...event?.mediaArr || []].sort(sortMedia), [event?.mediaArr]);

    useEffect(() => {
        setActivekey(sortedMediaArr.length > 0 ? `${sortedMediaArr[0].type}${sortedMediaArr[0].source}${sortedMediaArr[0].index}` : null);
    }, [sortedMediaArr])

    const [activeKey, setActivekey] = useState<string | null>(sortedMediaArr.length > 0 ? `${sortedMediaArr[0].type}${sortedMediaArr[0].source}${sortedMediaArr[0].index}` : null);


    const deviceId = event?.deviceId;
    const messageId = event?.messageId;

    if (!(deviceId && messageId)) return null;

    return (
        <Tabs
            id="EventDetailMediaTabs"
            className={styles["tabs"]}
            onSelect={(event) => setActivekey(event)}
            activeKey={activeKey || ""}
        >
            {
                sortedMediaArr.map((mediaDetails) => (
                    <Tab eventKey={`${mediaDetails.type}${mediaDetails.source}${mediaDetails.index}`}
                        title={mediaDetails.type === MediaType.Video ?
                            activeKey === `${mediaDetails.type}${mediaDetails.source}${mediaDetails.index}` ? <><img className="green-circle" src={greenCirclePath} alt="gs" /><Video color={Color.WHITE} key={`${mediaDetails.type}${mediaDetails.source}${mediaDetails.index}`} /></> :
                                <Video color={Color.DARK_GRAY_1} key={`${mediaDetails.type}${mediaDetails.source}${mediaDetails.index}`} /> :
                            activeKey === `${mediaDetails.type}${mediaDetails.source}${mediaDetails.index}` ? <><img className="green-circle" src={greenCirclePath} alt="gs" />  <Photo color={Color.WHITE} key={`${mediaDetails.type}${mediaDetails.source}${mediaDetails.index}`} /></> :
                                <Photo color={Color.DARK_GRAY_1} key={`${mediaDetails.type}${mediaDetails.source}${mediaDetails.index}`} />}
                        key={`${mediaDetails.type}${mediaDetails.source}${mediaDetails.index}`}
                    >
                        <Media
                            urlMain={eventsService.getUrlForMedia({
                                messageId,
                                deviceId,
                                type: mediaDetails.type,
                                source: mediaDetails.source,
                                index: mediaDetails.index,
                            })}
                            width="100%"
                            height="100%"
                            eventType={event?.type}
                            subReason={event?.eventAttr1}
                            mediaDetails={mediaDetails}
                            nodata={mediaDetails.nodata}
                        />
                    </Tab>
                ))
            }
        </Tabs>
    )
}

export default EventDetailMediaTabs;