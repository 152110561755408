import { ReactNode, useEffect, useRef, useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import styles from "./OverlayItem.module.scss";

interface OverlayItemProps {
    value: ReactNode | number | string | undefined | null;
    position: "top" | "bottom" | "right" | "left";
    className?: string;
}

export function OverlayItem({ value, position, className }: OverlayItemProps) {

    const scheduleRef: any = useRef(null);
    const [isOverflowing, setIsOverflowing] = useState(false);

    useEffect(() => {
        if (scheduleRef.current) {
            setIsOverflowing(scheduleRef.current.scrollWidth > scheduleRef.current.clientWidth);
        }
    }, [value]);

    return (
        <OverlayTrigger
            placement={position}
            overlay={<Tooltip>{value}</Tooltip>}
            trigger={isOverflowing ? 'hover' : []} // Use an array for no triggers
        >
            <div ref={scheduleRef} className={`${isOverflowing ? styles["overlayText"] : ""} ${className}`}>{value}</div>
        </OverlayTrigger>
    )
}