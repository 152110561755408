import { Node as TNode } from "react-checkbox-tree";

const ruleEventServerNameToEventMeta = {
  DriverAsleep: {
    displayName: "Driver Asleep",
    category: "DMS"
  },
  DriverDrowsiness: {
    displayName: "Drowsiness",
    category: "DMS"
  },
  DriverDistraction: {
    displayName: "Driver Distraction",
    category: "DMS"
  },
  PhoneUse: {
    displayName: "Phone Use",
     category: "DMS"
  },
  UnfastenedSeatbelt: {
    displayName: "Seatbelt",
    category: "DMS"
  },
  DriverSmoking: {
    displayName: "Smoking",
    category: "DMS"
  },
  TamperingDetection: {
    displayName: "Tampering Detection",
    category: "DMS"
  },
  DriverIdentified: {
    displayName: "Driver Identified",
    category: "DMS"
  },
  UnidentifiedUnauthDriver: {
    displayName: "Unidentified driver",
    category: "DMS"
  },
  DriverDisappeared: {
    displayName: "Driver Disappeared",
    category: "DMS"
  },
  DriverChanged: {
    displayName: "Driver Changed",
    category: "DMS"
  },
  DriverIDUpdated: {
    displayName: "Driver ID Updated",
    category: "DMS"
  },
  LDW: {
    displayName: "Lane Departure Warning",
    category: "ADAS"
  },
  HMW: {
    displayName: "Tailgating",
    category: "ADAS"
  },
  UFCW: {
    displayName: "Urban Forward Collision Warning",
    category: "ADAS"
  },
  FCW: {
    displayName: "Forward Collision Warning",
    category: "ADAS"
  },
  PCW: {
    displayName: "Pedestrian Collision Warning",
    category: "ADAS"
  },
  IgnitionON: {
    displayName: "Ignition ON",
    category: "Tracking"
  },
  IgnitionOFF: {
    displayName: "Ignition Off",
    category: "Tracking"
  },
  MovementStarted: {
    displayName: "Movement Started",
    category: "Tracking"
  },
  MovementStopped: {
    displayName: "Movement Stopped",
    category: "Tracking"
  },

} as const;

export type RuleEventServerName = keyof typeof ruleEventServerNameToEventMeta;
export type RuleEventMeta = (typeof ruleEventServerNameToEventMeta)[RuleEventServerName];
export type RuleEventCategory = RuleEventMeta["category"];

export function getRuleEventsCheckboxTree(className?: string): TNode[] {
  const categoryNodes: TNode[] = [];
  const categoryNameToNode: Map<RuleEventCategory, TNode> = new Map();

  for (const [serverName,ruleEventMeta] of Object.entries(ruleEventServerNameToEventMeta)) {
    const category = ruleEventMeta.category;

    if (!categoryNameToNode.has(category)) {
      const newCategoryNode: TNode = { label: category, value: category, children: [] };
      categoryNodes.push(newCategoryNode);
      categoryNameToNode.set(category, newCategoryNode);
    }
    categoryNameToNode.get(category)?.children?.push({ label: ruleEventMeta.displayName, value: serverName });
  }

  return [{ label: "All", value: "", children: categoryNodes, className }];
}
