import { CellProps, Column } from "react-table";
import { RuleTableEntry } from "./RulesTable";
import styles from "./RulesTable.module.scss";
import { GetRulesResult, RulesSortFilterParamName } from "../../services/rule.service";
import { FormattedMessage } from "react-intl";
import CheckboxInput from "../CheckboxInput/CheckboxInput";
import SortingHeaderContainer from "../SortingHeaderContainer/SortingHeaderContainer";
import { RULES_CONTEXT } from "../../context/QueryParamsContext/queryParamContextObjects";
import { Col, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { getFrontDtFormat, dateUtils } from "../../util/date.util";
import { LoggedUser } from "../../context/AuthContext";
import { Constants } from "../../constants";
import { Role, RuleWithMoreData } from "../../api/data-contracts";
import { OverlayItem } from "../Overlay/OverlayItem";
import React from "react";

export function queryResultToTableData(notifications: GetRulesResult) {
  return {
    pageCount: notifications.pageCount,
    totalCount: notifications.totalCount,
    data: rulesEntries(notifications.data || [])
  };
}

function rulesEntries(rules: RuleWithMoreData[]): RuleTableEntry[] {
  return rules.map(
    (msg): RuleTableEntry => {
      return {
        Id: msg.Id,
        name: msg.name,
        description: msg.description,
        ruleNotificationTypes: msg.ruleNotificationTypes,
        ruleSchedules: msg.ruleSchedules,
        createdDateTime: msg.createdDateTime,
        createdUserName: msg.createdUserName,

      }
    });
}

export function generateRulesColumns(loggedUser: LoggedUser | undefined): Column<RuleTableEntry>[] {
  return [
    
     {
      id: "checkbox",
      Cell: ({ row }: CellProps<RuleTableEntry>) => (
         (loggedUser?.role == Role.FleetUser ||  loggedUser?.role == Role.FleetManager) ?
         <CheckboxInput
          checked={row.isSelected ? "FULL" : "EMPTY"}
          onClick={() => row.toggleRowSelected(!row.isSelected)}
        />
         : <div></div>
      ),
    },
    {
      accessor: "name",
      Header: (
        <SortingHeaderContainer<RulesSortFilterParamName> context={RULES_CONTEXT} paramName="name">
          <FormattedMessage id="NAME" defaultMessage="Name" />
        </SortingHeaderContainer>
      ),
      Cell: ({ value }: CellProps<RuleTableEntry>) => {
        return <OverlayItem position="bottom" value={value} className={styles["overlay"]} />
      },
    },
    {
      accessor: "description",
      Header: (
        <SortingHeaderContainer<RulesSortFilterParamName> context={RULES_CONTEXT} paramName="description">
          <FormattedMessage id="NOTIFICATIONS_TABLE.DESCRIPTION" defaultMessage="Description" />
        </SortingHeaderContainer>
      ),
      Cell: ({ value }: CellProps<RuleTableEntry>) => {
        return <OverlayItem position="bottom" value={value} className={styles["overlay"]}/>
      },
    },
    {
      accessor: "ruleSchedules",
      Header: (
        <FormattedMessage id="NOTIFICATIONS_TABLE.SCHEDULE" defaultMessage="Schedule" />
      ),
      Cell: ({ value }: CellProps<RuleTableEntry>) => {
        return <OverlayTrigger
          placement="bottom"
          offset={[30, 0]}
          overlay={
            <Tooltip>
              {value && value.length > 0 &&
                <Row>
                  <Col xl="4" style={{ color: "#B5B5B5", marginBottom: "5px", textAlign: "left" }}>
                  <FormattedMessage id="DAY" defaultMessage="Day" />
                  </Col>
                  <Col xl="8" style={{ color: "#B5B5B5", marginBottom: "5px", textAlign: "left" }}>
                  <FormattedMessage id="NOTIFICATIONS_TABLE.SCHEDULE" defaultMessage="Schedule" />
                  </Col>
                  {
                  value.map((x: any, index: number) => (
                    <React.Fragment key={index}>
                      <Col xl="4" style={{ textAlign: "left" }}>
                        {x.weekDay.toString().substring(0, 3)}
                      </Col>
                      <Col xl="8" style={{ textAlign: "left" }}>
                        {x.fromTime.toString().substring(0, 5)}-{x.toTime.toString().substring(0, 5)}
                      </Col>
                    </React.Fragment>
                     ))
                  }
                </Row>
              }

            </Tooltip>
          }
        >
          <div className={styles["schedule"]}>{value && value.length > 0 ? value.map((x: any) => `${x.weekDay.toString().substring(0, 3)}, ${x.fromTime.toString().substring(0, 5)}-${x.toTime.toString().substring(0, 5)} `).toString() : null}</div>

        </OverlayTrigger>;
      },
    },
    {
      accessor: "ruleNotificationTypes",
      Header: (
        <FormattedMessage id="NOTIFICATIONS_TABLE.NOTIFYBY" defaultMessage="Notify By" />
      ),
      Cell: ({ value }: CellProps<RuleTableEntry>) => {
        return <div>{value && value.length > 0 ? value.map((x: any) => x.notification_type).toString() : null}</div>;
      },
    },
    {
      accessor: "createdDateTime",
      Header: (
        <SortingHeaderContainer<RulesSortFilterParamName> context={RULES_CONTEXT} paramName="createdDateTime">
          <FormattedMessage id="NOTIFICATIONS_TABLE.CREATED" defaultMessage="Created" />
        </SortingHeaderContainer>
      ),
      Cell: ({ value }: CellProps<RuleTableEntry>) => {
        return !value ? null : <div>{dateUtils.getFormattedLocalDate(value, getFrontDtFormat(loggedUser?.profile?.dateTimeFormat)?.timeDate || Constants.DEFAULT_FRONT_DT_FORMAT.timeDate)}</div>;
      },
    },
    {
      accessor: "createdUserName",
      Header: (
        <SortingHeaderContainer<RulesSortFilterParamName> context={RULES_CONTEXT} paramName="createdUserName">
          <FormattedMessage id="NOTIFICATIONS_TABLE.CREATEDBY" defaultMessage="Created By" />
        </SortingHeaderContainer>
      ),
      Cell: ({ value }: CellProps<RuleTableEntry>) => {
        return <div>{value}</div>;
      },
    },
  ];
}
