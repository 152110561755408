import { EventsSortParam, GetEventsResult } from "../services/events.service";
import { useQuery } from "@tanstack/react-query";
import { eventsNextQuery, eventsQuery } from "../queries/events.query";
import { useEventsQueryParamsContext } from "../context/QueryParamsContext/EventsQueryParamsContextProvider";
import { useEffect } from "react";
import useFilterByTspFleetContext from "./useFilterByTspFleetContext";

import { areFiltersDistinct } from "../util/filtering.util";
import { EVENTS_TYPES_COUNT } from "../util/events.util";

function useQueryEvents<TTarget extends { pageCount?: number }>(
  selector: (queryResult: GetEventsResult) => TTarget,
  enabled: boolean = true,
  queryAll: boolean = false
) {
  let { queryParams, setPageIndex, upsertFilter, removeFilter } = useEventsQueryParamsContext();
  const { isApplied: isTspFleetFilterApplied, isRequired: isTspFleetFilterRequired } = useFilterByTspFleetContext(
    upsertFilter,
    removeFilter,
    queryParams.filters
  );
  const showData = !isTspFleetFilterRequired || isTspFleetFilterApplied;

  const eventTypeFilter = queryParams.filters.find(
    (filter) => !areFiltersDistinct(filter, { paramName: "eventType", operator: "==" })
  );

  if (eventTypeFilter?.values.length === EVENTS_TYPES_COUNT) {
    queryParams = {
      ...queryParams,
      filters: queryParams.filters.filter((filterParam) =>
        areFiltersDistinct(filterParam, { paramName: "eventType", operator: "==" })
      ),
    };
  }
  var qParams = { ...queryParams, paging: queryAll ? undefined : queryParams.paging };
  const { data, isLoading, isError, isFetching } = useQuery(eventsQuery(qParams, selector, showData && !!enabled));

  useEffect(() => {
    const pageCount = data?.pageCount;
    if (!isFetching && pageCount && queryParams.paging.pageIndex > pageCount) {
      setPageIndex(pageCount);
    }
  }, [data, isFetching, queryParams, setPageIndex]);

  return { data: showData ? data : undefined, isLoading: isLoading && isFetching, isError, isFetching };
}

export function useQueryMapEvents<TTarget extends { pageCount?: number }>(
  selector: (queryResult: GetEventsResult) => TTarget,
  enabled: boolean = true,
  queryAll: boolean = false
) {
  let { queryParams, upsertFilter, removeFilter } = useEventsQueryParamsContext();
  const { isApplied: isTspFleetFilterApplied, isRequired: isTspFleetFilterRequired } = useFilterByTspFleetContext(
    upsertFilter,
    removeFilter,
    queryParams.filters
  );
  const showData = !isTspFleetFilterRequired || isTspFleetFilterApplied;

  var sorts: EventsSortParam[] = [{ param: "eventDateTime", descending: true }];

  queryParams = {
    ...queryParams,
    filters: queryParams.filters.filter((filterParam) =>
      areFiltersDistinct(filterParam, { paramName: "category", operator: "==" })
    ),
  };

  var qParams = { ...queryParams, paging: queryAll ? undefined : queryParams.paging, sorts };
  const { data, isLoading, isError, isFetching } = useQuery(eventsQuery(qParams, selector, showData && !!enabled));

  return { data: showData ? data : undefined, isLoading: isLoading && isFetching, isError, isFetching };
}

export function useQueryNextEvents<TTarget extends { pageCount?: number }>(
  selector: (queryResult: GetEventsResult) => TTarget,
  enabled: boolean = true,
  pageIndex: number,
) {
  let { queryParams, upsertFilter, removeFilter } = useEventsQueryParamsContext();
  const { isApplied: isTspFleetFilterApplied, isRequired: isTspFleetFilterRequired } = useFilterByTspFleetContext(
    upsertFilter,
    removeFilter,
    queryParams.filters
  );
  const showData = !isTspFleetFilterRequired || isTspFleetFilterApplied;

  const eventTypeFilter = queryParams.filters.find(
    (filter) => !areFiltersDistinct(filter, { paramName: "eventType", operator: "==" })
  );

  if (eventTypeFilter?.values.length === EVENTS_TYPES_COUNT) {
    queryParams = {
      ...queryParams,
      filters: queryParams.filters.filter((filterParam) =>
        areFiltersDistinct(filterParam, { paramName: "eventType", operator: "==" })
      ),
    };
  }
  var qParams = { ...queryParams, paging: { pageIndex: pageIndex + 1, pageSize: 1 } };
  const { data, isLoading, isError, isFetching } = useQuery(eventsNextQuery(qParams, selector, showData && !!enabled));

  // useEffect(() => {
  //   const pageCount = data?.pageCount;
  //   if (!isFetching && pageCount && queryParams.paging.pageIndex > pageCount) {
  //     setPageIndex(pageCount);
  //   }
  // }, [data, isFetching, queryParams, setPageIndex]);

  return { data: showData ? data : undefined, isLoading: isLoading && isFetching, isError, isFetching };
}

export default useQueryEvents;
